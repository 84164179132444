export default function ProfileIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <circle
        cx="13.9997"
        cy="13.9997"
        r="12.2551"
        stroke="#7C7C7C"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.4466 19.4465C19.2883 19.048 19.0426 18.6902 18.7277 18.3993V18.3993C18.197 17.9061 17.4995 17.6318 16.775 17.6313H11.2262C10.5013 17.6318 9.80339 17.9061 9.27219 18.3993V18.3993C8.95797 18.6908 8.7124 19.0485 8.55322 19.4465"
        stroke="#7C7C7C"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <ellipse
        cx="13.9998"
        cy="11.617"
        rx="3.06378"
        ry="3.06378"
        stroke="#7C7C7C"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
