export function GiftIcon() {
  return (
    <svg
      width="32"
      height="20"
      viewBox="0 0 32 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.4925 5.33425H16.74H11.6294C10.562 5.33425 9.69678 4.46899 9.69678 3.40164C9.69678 1.67032 11.7982 0.812435 13.0099 2.04912L16.064 5.16633L19.1121 2.05175C20.3227 0.814725 22.424 1.67188 22.424 3.40274C22.424 4.46948 21.5593 5.33425 20.4925 5.33425Z"
        stroke="white"
        stroke-width="0.969697"
        stroke-linejoin="round"
      />
      <rect
        x="1.61131"
        y="5.3635"
        width="28.9382"
        height="13.5376"
        rx="2.97484"
        stroke="white"
        stroke-width="0.969697"
      />
      <path
        d="M9.89946 11.2886C9.8536 11.1291 9.78916 10.9882 9.70617 10.8659C9.62317 10.7414 9.5216 10.6366 9.40148 10.5514C9.28353 10.464 9.14812 10.3974 8.99523 10.3516C8.84452 10.3057 8.67743 10.2828 8.49396 10.2828C8.15105 10.2828 7.84964 10.3679 7.58973 10.5383C7.332 10.7087 7.13106 10.9566 6.9869 11.282C6.84275 11.6053 6.77067 12.0006 6.77067 12.468C6.77067 12.9354 6.84166 13.3329 6.98363 13.6605C7.1256 13.9882 7.32654 14.2382 7.58645 14.4108C7.84636 14.5812 8.15324 14.6663 8.50707 14.6663C8.82814 14.6663 9.10225 14.6096 9.3294 14.496C9.55874 14.3802 9.73347 14.2175 9.8536 14.0078C9.97591 13.7981 10.0371 13.5502 10.0371 13.2641L10.3254 13.3067H8.59553V12.2387H11.4032V13.0839C11.4032 13.6736 11.2788 14.1804 11.0298 14.6041C10.7808 15.0256 10.4379 15.3511 10.001 15.5804C9.5642 15.8076 9.06403 15.9211 8.50052 15.9211C7.87148 15.9211 7.31889 15.7824 6.84275 15.5051C6.3666 15.2255 5.9953 14.8291 5.72883 14.3158C5.46455 13.8003 5.33241 13.1888 5.33241 12.4811C5.33241 11.9372 5.41104 11.4524 5.5683 11.0265C5.72774 10.5984 5.95052 10.2358 6.23665 9.93875C6.52277 9.64171 6.85585 9.41565 7.23589 9.26058C7.61594 9.1055 8.02765 9.02797 8.47103 9.02797C8.85107 9.02797 9.2049 9.08366 9.53253 9.19505C9.86015 9.30426 10.1506 9.45933 10.404 9.66028C10.6595 9.86122 10.8681 10.1004 11.0298 10.3778C11.1914 10.653 11.2951 10.9566 11.341 11.2886H9.89946ZM14.2565 9.1197V15.8294H12.8379V9.1197H14.2565ZM15.7927 15.8294V9.1197H20.2352V10.2893H17.2113V11.8881H19.9404V13.0577H17.2113V15.8294H15.7927ZM21.3029 10.2893V9.1197H26.8135V10.2893H24.7593V15.8294H23.3571V10.2893H21.3029Z"
        fill="white"
      />
    </svg>
  );
}
