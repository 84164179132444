import { Accessor, Show } from "solid-js";
import { BalancePill } from "./brand_collection_components";
import { HubbleFullSvg } from "~/assets/assets";
import { GiftIcon } from "~/assets/svg_icons/gift_icon";
import { classList } from "solid-js/web";
import { zIndexes } from "~/z_indexes";
import ProfileIcon from "~/assets/svg_icons/profile_icons";
import { A } from "@solidjs/router";

export type BrandCollectionHeaderProps = {
  showBalance: Accessor<boolean>;
  showProfile: Accessor<boolean>;
  isAbsolute?: boolean;
  showHeader?: Accessor<boolean>;
  coinsAvailable: Accessor<number | null>;
  giftingKey: string;
  logoUrl?: string;
};

export function BrandCollectionHeader(props: BrandCollectionHeaderProps) {
  return (
    <div
      class={`bg-baseDark top-0 flex w-full  flex-row items-center justify-center px-4  py-3 text-baseTertiaryLight duration-300`}
      classList={{
        absolute: props.isAbsolute ?? true,
        "sticky top-0": !props.isAbsolute,
        "justify-between": props.showBalance() || props.showProfile(),
        "translate-y-[-60px]": !(props.showHeader?.() ?? true),
      }}
      style={{
        "z-index": zIndexes.header,
      }}
    >
      {buildLogo()}
      {buildButtons()}
    </div>
  );

  function buildLogo() {
    return (
      <div
        onClick={() =>
          (window.location.href = "/gift-box/m/" + props.giftingKey)
        }
        class="flex  flex-row items-center gap-1  py-1 "
        classList={{
          "flex-1 w-full": !!props.logoUrl,
          " w-min": !props.logoUrl,
          "justify-center": !(props.showBalance() || props.showProfile()),
        }}
      >
        <Show
          when={props.logoUrl}
          fallback={
            <>
              <HubbleFullSvg fill="#ffffff" class="h-5" />
              <GiftIcon />
            </>
          }
        >
          <img src={props.logoUrl!} alt="" class="h-[28px] " />
        </Show>
      </div>
    );
  }

  function buildButtons() {
    return (
      <div class="flex flex-row items-start gap-2">
        <Show when={props.showBalance()}>
          <BalancePill
            coinsAvailable={props.coinsAvailable() ?? 0}
            large={false}
            name="Hubble"
            setRefreshCoins={() => {}}
            giftingKey={props.giftingKey}
          />
        </Show>
        <Show when={props.showProfile()}>
          <A href={`/gift-box/m/${props.giftingKey}/profile`}>
            <ProfileIcon />
          </A>
        </Show>
      </div>
    );
  }
}
